/* You can add global styles to this file, and also import other style files */

*   {
    font-family: 'Open Sans', sans-serif;
}

body, html{
    margin:0;
    padding: 0;
}

div#header {
    background: #004d71;
    padding:15px;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    box-sizing: border-box;
    height:64px;
}

#header img{
    width:200px;
}

html, body, #app-content  {
    height: 100%;
}


